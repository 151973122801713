import type { ApolloError, QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { compact } from 'lodash-es';
import { useMemo } from 'react';

import type {
  CrossSellGrowthRecommendationPreviewFieldsFragment,
  FullChurnRiskRecommendationPreviewFieldsFragment,
  GetClientGrowthRecommendationsPreviewQuery,
  GetClientRiskRecommendationsPreviewQuery,
  PartialChurnRiskRecommendationPreviewFieldsFragment,
} from '../graphql/generated';
import {
  GetClientGrowthRecommendationsPreviewDocument,
  GetClientRiskRecommendationsPreviewDocument,
  GetMyWorkRecommendationsPreviewDocument,
} from '../graphql/generated';
import type { GrowthRecommendation, RiskRecommendation } from '../models/recommendation';
import type { Result } from '../models/result';
import { ResultType, useResultFromQuery } from '../models/result';
import { asUUID } from '../models/uuid';
import { transformDate } from '../utils';

const transformFullChurnRiskRecommendationPreview = <
  T extends FullChurnRiskRecommendationPreviewFieldsFragment,
>(
  item: T,
): RiskRecommendation => ({
  account: {
    customerHealthscore: item.account.current?.customHealthValue ?? null,
    id: item.account.current?.id ?? asUUID(''), // FIXME: nullable account id
    name: item.account.current?.name ?? '', // FIXME: nullable account name
    ownerEmail: item.account.current?.roles.primaryOwner?.email ?? null,
    ownerName: item.account.current?.roles.primaryOwner?.name ?? null,
    renewalOwnerEmail: item.account.current?.roles.renewalOwner?.email ?? null,
    renewalOwnerName: item.account.current?.roles.renewalOwner?.name ?? null,
  },
  contract: {
    contractEndDate: null,
    contractEndFiscalQuarter: '', // FIXME: nullable contract end FQ
    expiringArr: null,
    id: asUUID(''), // FIXME: nullable contract id
    link: null,
    name: null,
    productNames: null,
    usageRating: null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current?.bucket ?? null,
          category: item.recommendationScore.current?.category ?? null,
          type: 'ACCOUNT_FULL_CHURN_RISK',
          value: item.recommendationScore.current?.value ?? null,
          color: item.recommendationScore.current?.color ?? null,
        }
      : null,
    originalScore: null, // Preview RiskRec never has original value
    relevantARR: item.relevantArr.current, // TODO: multi-currency conversion
  },
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'FULL_CHURN_CUSTOMER',
});
const transformPartialChurnRiskRecommendationPreview = <
  T extends PartialChurnRiskRecommendationPreviewFieldsFragment,
>(
  item: T,
): RiskRecommendation => ({
  account: {
    customerHealthscore: item.account.current?.customHealthValue ?? null,
    id: item.account.current?.id ?? asUUID(''), // FIXME: nullable account id
    name: item.account.current?.name ?? '', // FIXME: nullable account name
    ownerEmail: item.account.current?.roles.primaryOwner?.email ?? null,
    ownerName: item.account.current?.roles.primaryOwner?.name ?? null,
    renewalOwnerEmail: item.account.current?.roles.renewalOwner?.email ?? null,
    renewalOwnerName: item.account.current?.roles.renewalOwner?.name ?? null,
  },
  contract: {
    contractEndDate: item.contract.current ? transformDate(item.contract.current?.endDate) : null,
    contractEndFiscalQuarter: item.contract.current?.endFiscalQuarter ?? '', // FIXME: nullable contract end FQ
    expiringArr: item.contract.current?.expiringArr ?? null,
    id: item.contract.current?.id ?? asUUID(''), // FIXME: nullable contract id
    // TODO: sub with crmLink when available
    link: item.account.current ? `/customer-details/${item.account.current.id}/contracts` : null,
    name: item.contract.current?.name ?? null,
    productNames: item.contract.current?.servicePeriods.map((sp) => sp.productName) ?? null,
    usageRating: item.contract.current?.usageRating ?? null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current.bucket,
          category: item.recommendationScore.current.category,
          type: 'CONTRACT_USAGE_RATING',
          value: item.recommendationScore.current.value,
          color: item.recommendationScore.current.color,
        }
      : null,
    originalScore: null, // Preview RiskRec never has original value
    relevantARR: item.relevantArr.current, // TODO: multi-currency conversion
  },
  owner:
    item.owner != null
      ? {
          id: item.owner?.id,
          email: item.owner?.email,
          name: item.owner?.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'PARTIAL_CHURN_CONTRACT',
});
const transformUserRiskRecommendationPreviews = <
  T extends
    | FullChurnRiskRecommendationPreviewFieldsFragment
    | PartialChurnRiskRecommendationPreviewFieldsFragment,
>(
  items: T[],
): RiskRecommendation[] =>
  compact(
    items.map((item) => {
      switch (item.__typename) {
        case 'ReefCustomerFullChurnRecommendation':
          return transformFullChurnRiskRecommendationPreview(item);
        case 'ReefContractPartialChurnRecommendation':
          return transformPartialChurnRiskRecommendationPreview(item);
        default:
          return null;
      }
    }),
  );

type ClientRiskRecommendationsPreviewResult =
  GetClientRiskRecommendationsPreviewQuery['client']['previewRecommendations'];
const isFullChurnRiskRecommendationPreview = (
  item: ClientRiskRecommendationsPreviewResult[0],
): item is FullChurnRiskRecommendationPreviewFieldsFragment =>
  item.__typename === 'ReefCustomerFullChurnRecommendation';
const isPartialChurnRiskRecommendationPreview = (
  item: ClientRiskRecommendationsPreviewResult[0],
): item is PartialChurnRiskRecommendationPreviewFieldsFragment =>
  item.__typename === 'ReefContractPartialChurnRecommendation';
const extractRiskRecommendationsPreview = <T extends ClientRiskRecommendationsPreviewResult>(
  items: T,
) =>
  items.filter(
    (item) =>
      isFullChurnRiskRecommendationPreview(item) || isPartialChurnRiskRecommendationPreview(item),
  );

const transformCrossSellGrowthRecommendationPreview = <
  T extends CrossSellGrowthRecommendationPreviewFieldsFragment,
>(
  item: T,
): GrowthRecommendation => ({
  account: {
    id: item.account.current?.id ?? asUUID(''), // FIXME: nullable account id
    name: item.account.current?.name ?? '', // FIXME: nullable account name
    ownerEmail: item.account.current?.roles.primaryOwner?.email ?? null,
    ownerName: item.account.current?.roles.primaryOwner?.name ?? null,
    customerStartedOn: transformDate(item.account.current?.customerStartedOn ?? null),
    rawPipeline: item.account.current?.rawPipeline ?? null,
    renewalDate: transformDate(item.account.current?.renewalDate ?? null),
    secondaryOwnerEmail: item.account.current?.roles.secondaryOwner?.email ?? null,
    secondaryOwnerName: item.account.current?.roles.secondaryOwner?.name ?? null,
  },
  createdOn: transformDate(item.createdOn),
  expired: item.expired,
  id: item.id,
  metrics: {
    currentPipeline: null,
    currentPredictedOverage: null,
    currentPredictedOverageDate: null,
    currentScore: item.recommendationScore.current
      ? {
          bucket: item.recommendationScore.current.bucket ?? null,
          category: item.recommendationScore.current.category ?? null,
          type: 'UNKNOWN',
          value: item.recommendationScore.current.value ?? null,
          color: item.recommendationScore.current.color ?? null,
        }
      : null,
    originalPipeline: null,
    originalPredictedOverage: null,
    originalPredictedOverageDate: null,
    originalScore: null, // Preview GrowthRec never has original value
    products: item.product.current ? [item.product.current] : [],
  },
  owner:
    item.owner != null
      ? {
          email: item.owner.email,
          id: item.owner.id,
          name: item.owner.name,
        }
      : null,
  playbook: {
    id: item.playbook.id,
    title: item.playbook.title,
  },
  sprint: null,
  status: item.status,
  statusUpdatedOn: transformDate(item.statusUpdatedOn),
  title: item.playbook.title,
  type: 'PRODUCT_CROSS_SELL',
});
const transformUserGrowthRecommendationPreviews = <
  T extends CrossSellGrowthRecommendationPreviewFieldsFragment,
>(
  items: T[],
): GrowthRecommendation[] =>
  compact(
    items.map((item) => {
      switch (item.__typename) {
        case 'ReefProductCrossSellRecommendation':
          return transformCrossSellGrowthRecommendationPreview(item);
        default:
          return null;
      }
    }),
  );
type ClientGrowthRecommendationsPreviewResult =
  GetClientGrowthRecommendationsPreviewQuery['client']['previewRecommendations'];
const isCrossSellGrowthRecommendationPreview = (
  item: ClientGrowthRecommendationsPreviewResult[0],
): item is CrossSellGrowthRecommendationPreviewFieldsFragment =>
  item.__typename === 'ReefProductCrossSellRecommendation';
const extractGrowthRecommendationsPreview = <T extends ClientGrowthRecommendationsPreviewResult>(
  items: T,
) => items.filter((item) => isCrossSellGrowthRecommendationPreview(item));

export const useClientRiskRecommendationsPreview = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<RiskRecommendation[], ApolloError> => {
  const clientRiskRecommendationsPreviewResult = useResultFromQuery(
    useQuery(GetClientRiskRecommendationsPreviewDocument, { skip }),
  );
  return useMemo((): Result<RiskRecommendation[], ApolloError> => {
    if (clientRiskRecommendationsPreviewResult.state === ResultType.Value) {
      const { previewRecommendations } = clientRiskRecommendationsPreviewResult.value.client;
      const riskRecommendations = extractRiskRecommendationsPreview(previewRecommendations);
      const value = transformUserRiskRecommendationPreviews(riskRecommendations);
      return { state: clientRiskRecommendationsPreviewResult.state, value };
    }
    return clientRiskRecommendationsPreviewResult;
  }, [clientRiskRecommendationsPreviewResult]);
};

export const useClientGrowthRecommendationsPreview = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<GrowthRecommendation[], ApolloError> => {
  const clientGrowthRecommendationsPreviewResult = useResultFromQuery(
    useQuery(GetClientGrowthRecommendationsPreviewDocument, { skip }),
  );
  return useMemo((): Result<GrowthRecommendation[], ApolloError> => {
    if (clientGrowthRecommendationsPreviewResult.state === ResultType.Value) {
      const { previewRecommendations } = clientGrowthRecommendationsPreviewResult.value.client;
      const growthRecommendations = extractGrowthRecommendationsPreview(previewRecommendations);
      const value = transformUserGrowthRecommendationPreviews(growthRecommendations);
      return { state: clientGrowthRecommendationsPreviewResult.state, value };
    }
    return clientGrowthRecommendationsPreviewResult;
  }, [clientGrowthRecommendationsPreviewResult]);
};

export const useMyRecommendationsPreview = ({
  options: { skip = false },
}: {
  options: Pick<QueryHookOptions, 'skip'>;
}): Result<(RiskRecommendation | GrowthRecommendation)[], ApolloError> => {
  const recommendationPreviewResult = useResultFromQuery(
    useQuery(GetMyWorkRecommendationsPreviewDocument, { skip }),
  );

  return useMemo((): Result<(RiskRecommendation | GrowthRecommendation)[], ApolloError> => {
    if (recommendationPreviewResult.state === ResultType.Value) {
      const { previewRecommendations } = recommendationPreviewResult.value.self.associated;
      const riskRecommendations = transformUserRiskRecommendationPreviews(
        extractRiskRecommendationsPreview(previewRecommendations),
      );
      const growthRecommendations = transformUserGrowthRecommendationPreviews(
        extractGrowthRecommendationsPreview(previewRecommendations),
      );
      return {
        state: recommendationPreviewResult.state,
        value: [...riskRecommendations, ...growthRecommendations],
      };
    }
    return recommendationPreviewResult;
  }, [recommendationPreviewResult]);
};
